'use client'

import { Box, Typography, ThemeProvider } from '@mui/material'
import theme from '../../../../../shared/theme/theme'

export default function GDPRPage() {
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					width: '100vw',
					minHeight: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '16px 0',
					background:
						'linear-gradient(135deg, #03254C 0%, #1167B1 100%)',
					backgroundSize: 'cover',
				}}
			>
				<Box
					sx={{
						width: { xs: '90%', sm: '80%', md: '70%' },
						position: 'relative',
						overflow: 'hidden',
						borderRadius: '20px',
						border: '2px solid #D0EFFF99',
						background:
							'linear-gradient(45deg, #03254CA6 0%, #03254C 100%)',
						backdropFilter: 'blur(4px)',
						padding: '32px',
						boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
					}}
				>
					<Typography variant='h4' sx={{ color: '#F3F3F3', mb: 4 }}>
						Politica de Confidențialitate LSAC LAN Party
					</Typography>

					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Liga Studenților din Facultatea de Automatică și
						Calculatoare (LSAC) se angajează să protejeze și să
						respecte confidențialitatea datelor dumneavoastră
						personale. Această politică descrie modul în care
						colectăm, folosim și protejăm informațiile personale
						furnizate în cadrul evenimentului LAN Party.
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						1. Informații Colectate
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Colectăm următoarele informații personale:
						<ul>
							<li>Nume și prenume</li>
							<li>Adresă de email</li>
							<li>Număr de telefon</li>
							<li>ID Discord</li>
							<li>Statut (student/elev)</li>
							<li>Copie legitimație</li>
						</ul>
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						2. Scopul Colectării
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Utilizăm aceste informații pentru:
						<ul>
							<li>
								Înregistrarea și gestionarea participării la
								eveniment
							</li>
							<li>
								Comunicarea informațiilor legate de eveniment
							</li>
							<li>Verificarea eligibilității participanților</li>
							<li>
								Asigurarea conformității cu regulamentul
								evenimentului
							</li>
						</ul>
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						3. Stocarea și Securitatea Datelor
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Datele sunt stocate în mod securizat pe platformele
						Google Workspace și sunt accesibile doar organizatorilor
						evenimentului. Implementăm măsuri tehnice și
						organizatorice adecvate pentru a proteja datele
						împotriva accesului neautorizat, modificării sau
						distrugerii.
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						4. Perioada de Păstrare
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Păstrăm datele personale pe durata evenimentului și
						pentru o perioadă rezonabilă după încheierea acestuia
						(maxim 6 luni) pentru scopuri de arhivare și raportare.
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						5. Drepturile Dumneavoastră
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Aveți următoarele drepturi:
						<ul>
							<li>Dreptul de acces la datele personale</li>
							<li>Dreptul la rectificarea datelor</li>
							<li>
								Dreptul la ștergerea datelor (&quot;dreptul de a
								fi uitat&quot;)
							</li>
							<li>Dreptul la restricționarea prelucrării</li>
							<li>Dreptul la portabilitatea datelor</li>
							<li>Dreptul la opoziție</li>
						</ul>
					</Typography>

					<Typography variant='h5' sx={{ color: '#D0EFFF', mb: 2 }}>
						6. Contact
					</Typography>
					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mb: 3 }}
					>
						Pentru orice întrebări sau solicitări legate de datele
						dumneavoastră personale, ne puteți contacta la:
						<br />
						Email: lsaciasi.lanparty@gmail.ro
						<br />
						Adresă: Camin T19,Strada Cristofor,etaj 3, Iași,Romania
					</Typography>

					<Typography
						variant='body1'
						sx={{ color: '#F3F3F3', mt: 4 }}
					>
						Prin participarea la LAN Party, confirmați că ați citit
						și înțeles această politică de confidențialitate și
						sunteți de acord cu colectarea și procesarea datelor
						dumneavoastră personale în scopurile menționate mai sus.
					</Typography>
				</Box>
			</Box>
		</ThemeProvider>
	)
}
