'use client'

import type React from 'react'

import { useEffect, useState } from 'react'
import {
	Stack,
	Typography,
	Button,
	TextField,
	IconButton,
	Box,
	CircularProgress,
	Snackbar,
	Alert,
	ThemeProvider,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Checkbox,
	FormControlLabel,
	Radio,
	RadioGroup,
	FormLabel,
	Link,
} from '@mui/material'
import { Info as InfoIcon, Add as AddIcon } from '@mui/icons-material'
import { MemberForm } from './components/MemberForm'
import theme from '../../../../shared/theme/theme'
import {
	REGULATIONS,
	REVOLUT_PAYMENT_LINK,
	PARTICIPATION_FEE,
} from '../constants'
import type { FormData, TeamMember } from '../types'
import CustomDialog from './components/CustomDialog'
import { FileUpload } from './components/FileUpload'
import { set } from 'mongoose'
type TeamMemberData = {
	name: string
	discordId: string
	type: 'student' | 'pupil'
	idCard: FileData | null
}
type FileData = {
	filename: string
	data: string
	contentType: string
}
const initialFormData: FormData = {
	captainName: '',
	captainSurname: '',
	captainPhone: '',
	captainEmail: '',
	captainType: 'student',
	teamName: '',
	selectedGame: '',
	teammates: [],
	joinNostalgia: false,
	nostalgiaGame: '',
	paymentId: '',
}

const fieldInfoText = {
	captainName: 'Introduceți numele căpitanului echipei',
	captainSurname: 'Introduceți prenumele căpitanului echipei',
	captainPhone:
		'Introduceți numărul de telefon al căpitanului (format: 07xxxxxxxx)',
	captainEmail:
		'Introduceți adresa de email a căpitanului pentru comunicări importante',
	teamName: 'Alegeți un nume unic pentru echipa voastră',
	paymentId: `Introduceți Revolut Tag-ul de pe care s-a realizat tranzacția (${REVOLUT_PAYMENT_LINK})`,
}

const gameOptions = [
	{ value: 'cs2', label: 'Counter-Strike 2' },
	{ value: 'fc25', label: 'FC 25' },
	{ value: 'lol', label: 'League of Legends' },
]

const nostalgiaGameOptions = [
	{ value: 'cs16', label: 'Counter-Strike 1.6' },
	{ value: 'Minecraft SG', label: 'Minecraft SG' },
]

export function Inscrieri_LanParty() {
	const [formData, setFormData] = useState<FormData>(initialFormData)
	const [errors, setErrors] = useState<Record<string, string>>({})
	const [infoDialogOpen, setInfoDialogOpen] = useState(false)
	const [currentInfoField, setCurrentInfoField] = useState('')
	const [eventInfoOpen, setEventInfoOpen] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [feedbackOpen, setFeedbackOpen] = useState(false)
	const [feedbackMessage, setFeedbackMessage] = useState('')
	const [checkedConditions, setCheckedConditions] = useState(false)
	const [checkedConditionsErr, setCheckedConditionsErr] = useState(false)
	const [feedbackSeverity, setFeedbackSeverity] = useState<
		'success' | 'error'
	>('success')

	const handleInputChange = (field: keyof FormData, value: any) => {
		setFormData({
			...formData,
			[field]: value,
			...(field === 'selectedGame' &&
				value !== 'cs2' && {
					teammates: [],
					reserve: undefined,
				}),
			...(field === 'joinNostalgia' && !value && { nostalgiaGame: '' }),
		})

		if (errors[field]) {
			setErrors({
				...errors,
				[field]: '',
			})
		}
	}

	const handleTeammateChange = (
		index: number,
		updates: Partial<TeamMember>
	) => {
		const updatedTeammates = [...formData.teammates]
		updatedTeammates[index] = {
			...updatedTeammates[index],
			...updates,
		}

		setFormData({
			...formData,
			teammates: updatedTeammates,
		})

		// Clear errors
		Object.keys(updates).forEach((field) => {
			const errorKey = `teammates.${index}.${field}`
			if (errors[errorKey]) {
				setErrors({
					...errors,
					[errorKey]: '',
				})
			}
		})
	}

	const handleReserveChange = (updates: Partial<TeamMember>) => {
		setFormData({
			...formData,
			reserve: {
				...formData.reserve!,
				...updates,
			},
		})

		// Clear errors
		Object.keys(updates).forEach((field) => {
			const errorKey = `reserve.${field}`
			if (errors[errorKey]) {
				setErrors({
					...errors,
					[errorKey]: '',
				})
			}
		})
	}

	const addTeammate = () => {
		setFormData({
			...formData,
			teammates: [
				...formData.teammates,
				{ id: Date.now(), name: '', discordId: '', type: 'student' },
			],
		})
	}

	const removeTeammate = (index: number) => {
		const updatedTeammates = [...formData.teammates]
		updatedTeammates.splice(index, 1)
		setFormData({
			...formData,
			teammates: updatedTeammates,
		})
	}

	const openInfoDialog = (field: string) => {
		setCurrentInfoField(field)
		setInfoDialogOpen(true)
	}

	const validateForm = (): boolean => {
		const newErrors: Record<string, string> = {}

		// Validate captain details
		if (!formData.captainName.trim()) {
			newErrors.captainName = 'Numele este obligatoriu'
		}

		if (!formData.captainSurname.trim()) {
			newErrors.captainSurname = 'Prenumele este obligatoriu'
		}

		const phoneRegex = /^07\d{8}$/
		if (!formData.captainPhone.trim()) {
			newErrors.captainPhone = 'Telefonul este obligatoriu'
		} else if (!phoneRegex.test(formData.captainPhone)) {
			newErrors.captainPhone = 'Format invalid (ex: 07xxxxxxxx)'
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!formData.captainEmail.trim()) {
			newErrors.captainEmail = 'Email-ul este obligatoriu'
		} else if (!emailRegex.test(formData.captainEmail)) {
			newErrors.captainEmail = 'Format email invalid'
		}

		// if (!formData.captainIdCard) {
		// 	newErrors['captainIdCard'] = 'Legitimația este obligatorie'
		// }

		// Validate team name
		if (!formData.teamName.trim()) {
			newErrors.teamName = 'Numele echipei este obligatoriu'
		}

		// Validate game selection
		if (!formData.selectedGame) {
			newErrors.selectedGame = 'Selectează un joc'
		}

		// Validate payment ID
		if (!formData.paymentId.trim()) {
			newErrors.paymentId = 'ID-ul tranzacției este obligatoriu'
		}

		// Validate team composition for CS2
		if (formData.selectedGame === 'cs2' || formData.selectedGame === 'lol') {
			const allMembers = [...formData.teammates, formData.reserve].filter(
				Boolean
			)
			const studentCount = allMembers.filter(
				(m) => m?.type === 'student'
			).length
			const pupilCount = allMembers.filter(
				(m) => m?.type === 'pupil'
			).length

			if (studentCount < 4) {
				newErrors.teammates =
					'Echipa trebuie să conțină 5 studenti pentru CS2 si LOL'
			}

			// if (pupilCount < 2) {
			// 	newErrors.teammates =
			// 		'Echipa trebuie să conțină cel puțin 2 elevi'
			// }

			formData.teammates.forEach((teammate, index) => {
				if (!teammate.name.trim()) {
					newErrors[`teammates.${index}.name`] =
						'Numele este obligatoriu'
				}
				if (!teammate.discordId.trim()) {
					newErrors[`teammates.${index}.discordId`] =
						'ID-ul Discord este obligatoriu'
				}
				if (!teammate.type) {
					newErrors[`teammates.${index}.type`] =
						'Selectează tipul participantului'
				}
				// if (!teammate.idCardFile) {
				// 	newErrors[`teammates.${index}.idCardFile`] =
				// 		'Legitimația este obligatorie'
				// }
			})

			if (formData.reserve) {
				if (!formData.reserve.name.trim()) {
					newErrors['reserve.name'] = 'Numele este obligatoriu'
				}
				if (!formData.reserve.discordId.trim()) {
					newErrors['reserve.discordId'] =
						'ID-ul Discord este obligatoriu'
				}
				if (!formData.reserve.type) {
					newErrors['reserve.type'] =
						'Selectează tipul participantului'
				}
				// if (!formData.reserve.idCardFile) {
				// 	newErrors['reserve.idCardFile'] =
				// 		'Legitimația este obligatorie'
				// }
			}
		}

		// Validate nostalgia game if nostalgia is selected
		if (formData.joinNostalgia && !formData.nostalgiaGame) {
			newErrors.nostalgiaGame = 'Selectează un joc pentru Nostalgia Party'
		}

		setErrors(newErrors)
		return Object.keys(newErrors).length === 0
	}

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		if (checkedConditions) {
			await handleSubmitForm(event)
			setCheckedConditions(false)
			setCheckedConditionsErr(false)
		} else {
			setFeedbackOpen(true)
			setCheckedConditionsErr(true)
			setFeedbackMessage('Trebuie sa bifezi acordul de prelucrare')
		}
	}

	useEffect(() => {
		const clearConditionErr = setTimeout(() => {
			setCheckedConditionsErr(false)
		}, 2000)
		if (checkedConditionsErr) {
			clearTimeout(clearConditionErr)
			return () => clearTimeout(clearConditionErr)
		}
		if (checkedConditions && checkedConditionsErr) {
			alert('Trebuie sa bifezi acordul de prelucrare')
			setCheckedConditionsErr(false)
		}
	}, [checkedConditions, checkedConditionsErr])
	const handleSubmitForm = async (event: React.FormEvent) => {
		event.preventDefault()

		if (!validateForm()) {
			// Your existing validation code
			return
		}

		setIsSubmitting(true)

		try {
			// Convert file to base64
			const convertFileToBase64 = (file: File) => {
				return new Promise<string>((resolve, reject) => {
					const reader = new FileReader()
					reader.readAsDataURL(file)
					reader.onload = () => resolve(reader.result as string)
					reader.onerror = (error) => reject(error)
				})
			}

			// Prepare JSON data
			const jsonData: {
				captainName: string
				captainSurname: string
				captainPhone: string
				captainEmail: string
				captainType: 'student' | 'pupil'
				teamName: string
				selectedGame: string
				paymentId: string
				joinNostalgia: boolean
				nostalgiaGame: string
				captainIdCard: FileData | null
				teammates: TeamMemberData[]
				reserve: TeamMemberData | null
			} = {
				captainName: formData.captainName,
				captainSurname: formData.captainSurname,
				captainPhone: formData.captainPhone,
				captainEmail: formData.captainEmail,
				captainType: formData.captainType,
				teamName: formData.teamName,
				selectedGame: formData.selectedGame,
				paymentId: formData.paymentId,
				joinNostalgia: formData.joinNostalgia,
				nostalgiaGame: formData.nostalgiaGame,
				captainIdCard: formData.captainIdCard
					? {
							filename: formData.captainIdCard.name,
							data: await convertFileToBase64(
								formData.captainIdCard
							),
							contentType: formData.captainIdCard.type,
					  }
					: null,
				teammates: [],
				reserve: null,
			}

			// Process teammates
			for (const teammate of formData.teammates) {
				const teammateData: TeamMemberData = {
					name: teammate.name,
					discordId: teammate.discordId,
					type: teammate.type,
					idCard: null,
				}

				if (teammate.idCardFile) {
					teammateData.idCard = {
						filename: teammate.idCardFile.name,
						data: await convertFileToBase64(teammate.idCardFile),
						contentType: teammate.idCardFile.type,
					}
				}

				jsonData.teammates.push(teammateData)
			}

			// Process reserve
			if (formData.reserve) {
				const reserveData: TeamMemberData = {
					name: formData.reserve.name,
					discordId: formData.reserve.discordId,
					type: formData.reserve.type,
					idCard: null,
				}

				if (formData.reserve.idCardFile) {
					reserveData.idCard = {
						filename: formData.reserve.idCardFile.name,
						data: await convertFileToBase64(
							formData.reserve.idCardFile
						),
						contentType: formData.reserve.idCardFile.type,
					}
				}

				jsonData.reserve = reserveData
			}
			console.log(jsonData)
			// Send JSON instead of FormData
			const response = await fetch(
				'https://script.google.com/macros/s/AKfycbzq2-yMoNDz9-ARQjyyEV-4ji9OO4brVzp57XK_kpEeIOyVkYV0WJzXhu8_xAwiSt1yPQ/exec',
				{
					method: 'POST',

					body: JSON.stringify(jsonData),
				}
			)

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			const result = await response.json()

			if (result.status === 'success') {
				setFeedbackMessage('Înregistrarea a fost trimisă cu succes!')
				setFeedbackSeverity('success')
				setFeedbackOpen(true)
				// Reset form if success
				setFormData(initialFormData)
			} else {
				throw new Error(
					result.message || 'A apărut o eroare nespecificată'
				)
			}
		} catch (error) {
			console.error('Eroare la înregistrare:', error)
			setFeedbackMessage(`Eroare la înregistrare:`)
			setFeedbackSeverity('error')
			setFeedbackOpen(true)
		} finally {
			setIsSubmitting(false)
		}
	}
	const getFileExtension = (filename: string) => {
		return filename.substring(filename.lastIndexOf('.'))
	}

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					width: '100vw',
					minHeight: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '16px 0',
					background:
						'linear-gradient(135deg, #03254C 0%, #1167B1 100%)',
					backgroundSize: 'cover',
				}}
			>
				<Box
					sx={{
						width: { xs: '90%', sm: '80%', md: '70%' },
						position: 'relative',
						overflow: 'hidden',
						borderRadius: '20px',
						border: '2px solid #D0EFFF99',
						background:
							'linear-gradient(45deg, #03254CA6 0%, #03254C 100%)',
						backdropFilter: 'blur(4px)',
						padding: '32px',
						boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Stack spacing={3}>
							{/* Header */}
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flexDirection: { xs: 'column', sm: 'row' },
									gap: 2,
								}}
							>
								<Typography
									variant='h4'
									fontWeight='800'
									color='#F3F3F3'
								>
									Înscriere LAN Party
								</Typography>
								<Box
									sx={{
										display: 'flex',
										gap: 2,
										alignItems: 'center',
									}}
								>
									<Typography
										variant='subtitle1'
										color='#D0EFFF'
										sx={{
											backgroundColor:
												'rgba(17, 103, 177, 0.3)',
											padding: '8px 16px',
											borderRadius: '8px',
											border: '1px solid #1167B1',
										}}
									>
										{`Cost: ${PARTICIPATION_FEE} lei/participant`}
									</Typography>
									<Button
										variant='outlined'
										onClick={() => setEventInfoOpen(true)}
										sx={{
											borderColor: '#D0EFFF',
											color: '#D0EFFF',
											'&:hover': {
												borderColor: 'white',
												backgroundColor:
													'rgba(255, 255, 255, 0.1)',
											},
										}}
									>
										<InfoIcon sx={{ mr: 1 }} /> Regulament
									</Button>
								</Box>
							</Box>

							<Typography fontWeight='200' color='#F3F3F3'>
								Completează formularul de mai jos pentru a
								înscrie echipa ta la LAN Party.
								{(formData.selectedGame === 'cs2' || formData.selectedGame === 'lol') &&
									formData.teammates.length > 0 && (
										<>
											<br />
											<strong>
												Cost total de plată:{' '}
												{PARTICIPATION_FEE *
													(1 +
														formData.teammates
															.length +
														(formData.reserve
															? 1
															: 0))}{' '}
												lei (
												{1 +
													formData.teammates.length +
													(formData.reserve
														? 1
														: 0)}{' '}
												participanți)
											</strong>
										</>
									)}
							</Typography>

							{/* Captain Information */}
							<Typography variant='h6' color='#D0EFFF'>
								Informații Căpitan
							</Typography>

							<Box
								sx={{
									display: 'flex',
									gap: 2,
									flexDirection: { xs: 'column', sm: 'row' },
								}}
							>
								<Box sx={{ position: 'relative', flex: 1 }}>
									<TextField
										fullWidth
										label='Nume Căpitan'
										value={formData.captainName}
										onChange={(e) =>
											handleInputChange(
												'captainName',
												e.target.value
											)
										}
										error={!!errors.captainName}
										helperText={errors.captainName}
										required
										InputProps={{
											endAdornment: (
												<IconButton
													size='small'
													onClick={() =>
														openInfoDialog(
															'captainName'
														)
													}
												>
													<InfoIcon
														sx={{
															color: '#03254C',
														}}
													/>
												</IconButton>
											),
										}}
									/>
								</Box>

								<Box sx={{ position: 'relative', flex: 1 }}>
									<TextField
										fullWidth
										label='Prenume Căpitan'
										value={formData.captainSurname}
										onChange={(e) =>
											handleInputChange(
												'captainSurname',
												e.target.value
											)
										}
										error={!!errors.captainSurname}
										helperText={errors.captainSurname}
										required
										InputProps={{
											endAdornment: (
												<IconButton
													size='small'
													onClick={() =>
														openInfoDialog(
															'captainSurname'
														)
													}
												>
													<InfoIcon
														sx={{
															color: '#03254C',
														}}
													/>
												</IconButton>
											),
										}}
									/>
								</Box>
							</Box>

							<Box
								sx={{
									display: 'flex',
									gap: 2,
									flexDirection: { xs: 'column', sm: 'row' },
								}}
							>
								<Box sx={{ position: 'relative', flex: 1 }}>
									<TextField
										fullWidth
										label='Telefon Căpitan'
										value={formData.captainPhone}
										onChange={(e) =>
											handleInputChange(
												'captainPhone',
												e.target.value
											)
										}
										error={!!errors.captainPhone}
										helperText={errors.captainPhone}
										required
										InputProps={{
											endAdornment: (
												<IconButton
													size='small'
													onClick={() =>
														openInfoDialog(
															'captainPhone'
														)
													}
												>
													<InfoIcon
														sx={{
															color: '#03254C',
														}}
													/>
												</IconButton>
											),
										}}
									/>
								</Box>

								<Box sx={{ position: 'relative', flex: 1 }}>
									<TextField
										fullWidth
										label='Email Căpitan'
										type='email'
										value={formData.captainEmail}
										onChange={(e) =>
											handleInputChange(
												'captainEmail',
												e.target.value
											)
										}
										error={!!errors.captainEmail}
										helperText={errors.captainEmail}
										required
										InputProps={{
											endAdornment: (
												<IconButton
													size='small'
													onClick={() =>
														openInfoDialog(
															'captainEmail'
														)
													}
												>
													<InfoIcon
														sx={{
															color: '#03254C',
														}}
													/>
												</IconButton>
											),
										}}
									/>
								</Box>
							</Box>

							<Box
								sx={{
									display: 'flex',
									gap: 2,
									alignItems: 'center',
								}}
							>
								<FormControl component='fieldset'>
									<FormLabel
										component='legend'
										sx={{ color: 'white' }}
									>
										Tip participant
									</FormLabel>
									<RadioGroup
										row
										value={formData.captainType}
										onChange={(e) =>
											handleInputChange(
												'captainType',
												e.target.value
											)
										}
									>
										<FormControlLabel
											value='student'
											control={<Radio />}
											label='Student'
											sx={{ color: 'white' }}
										/>
										<FormControlLabel
											value='pupil'
											control={<Radio />}
											label='Elev'
											sx={{ color: 'white' }}
										/>
									</RadioGroup>
								</FormControl>

								<Box sx={{ flex: 1 }}>
									<FileUpload
										onFileSelect={(file) =>
											handleInputChange(
												'captainIdCard',
												file
											)
										}
										onFileRemove={() =>
											handleInputChange(
												'captainIdCard',
												undefined
											)
										}
										selectedFile={formData.captainIdCard}
										error={errors.captainIdCard}
									/>
								</Box>
							</Box>

							{/* Team Information */}
							<Typography variant='h6' color='#D0EFFF'>
								Informații Echipă
							</Typography>

							<Box sx={{ position: 'relative' }}>
								<TextField
									fullWidth
									label='Nume Echipă'
									value={formData.teamName}
									onChange={(e) =>
										handleInputChange(
											'teamName',
											e.target.value
										)
									}
									error={!!errors.teamName}
									helperText={errors.teamName}
									required
									InputProps={{
										endAdornment: (
											<IconButton
												size='small'
												onClick={() =>
													openInfoDialog('teamName')
												}
											>
												<InfoIcon
													sx={{ color: '#03254C' }}
												/>
											</IconButton>
										),
									}}
								/>
							</Box>

							{/* Game Selection */}
							<Typography variant='h6' color='#D0EFFF'>
								Informații Joc
							</Typography>

							<FormControl
								fullWidth
								error={!!errors.selectedGame}
							>
								<InputLabel id='game-select-label'>
									Alege Jocul
								</InputLabel>
								<Select
									labelId='game-select-label'
									value={formData.selectedGame}
									label='Alege Jocul'
									onChange={(e) =>
										handleInputChange(
											'selectedGame',
											e.target.value
										)
									}
								>
									{gameOptions.map((game) => (
										<MenuItem
											key={game.value}
											value={game.value}
										>
											{game.label}
										</MenuItem>
									))}
								</Select>
								{errors.selectedGame && (
									<Typography color='error' variant='caption'>
										{errors.selectedGame}
									</Typography>
								)}
							</FormControl>

							{/* Teammates Section - Only show for CS2 */}
							{(formData.selectedGame === 'cs2' || formData.selectedGame === 'lol')  && (
								<>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
										}}
									>
										<Typography
											variant='h6'
											color='#D0EFFF'
										>
											Coechipieri (Minim 3 studenți și 2
											elevi majori)
										</Typography>
										{/* <IconButton
											onClick={addTeammate}
											sx={{
												backgroundColor: '#1167B17A',
												color: 'white',
												'&:hover': {
													backgroundColor: '#1167B1',
												},
											}}
										>
											<AddIcon />
										</IconButton> */}
									</Box>

									{formData.teammates.length === 0 ? (
										<Typography
											color='#D0EFFF'
											variant='body2'
										>
											Adaugă coechipieri folosind butonul
											de mai jos
										</Typography>
									) : (
										<Stack spacing={2}>
											{formData.teammates.map(
												(teammate, index) => (
													<MemberForm
														key={teammate.id}
														member={teammate}
														index={index}
														onChange={(updates) =>
															handleTeammateChange(
																index,
																updates
															)
														}
														onDelete={() =>
															removeTeammate(
																index
															)
														}
														errors={errors}
													/>
												)
											)}
										</Stack>
									)}
									<Box
										sx={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											backgroundColor:
												'rgba(255, 255, 255, 0.1)',
											color: 'white',
											p: 2,
											borderRadius: '8px',
											'&:hover': {
												backgroundColor: '#1167B1',
												cursor: 'pointer',
											},
										}}
										onClick={addTeammate}
									>
										Adauga
										<AddIcon />
									</Box>
									{/* Reserve Player */}
									<Typography variant='h6' color='#D0EFFF'>
										Rezervă (Opțional)
									</Typography>

									{formData.reserve ? (
										<MemberForm
											member={formData.reserve}
											onChange={handleReserveChange}
											errors={errors}
											isReserve
										/>
									) : (
										<Button
											variant='outlined'
											onClick={() =>
												handleInputChange('reserve', {
													id: Date.now(),
													name: '',
													discordId: '',
													type: 'student',
												})
											}
											sx={{
												borderColor: '#D0EFFF',
												color: '#D0EFFF',
												'&:hover': {
													borderColor: 'white',
													backgroundColor:
														'rgba(255, 255, 255, 0.1)',
												},
											}}
										>
											Adaugă Rezervă
										</Button>
									)}
								</>
							)}

							{/* Payment Information */}
							<Typography variant='h6' color='#D0EFFF'>
								Informații Plată
							</Typography>
							{errors.teammates && (
								<Typography color='error' variant='body2'>
									{errors.teammates}
								</Typography>
							)}

							<Box sx={{ position: 'relative' }}>
								<TextField
									fullWidth
									label='Revolut Tag'
									value={formData.paymentId}
									onChange={(e) =>
										handleInputChange(
											'paymentId',
											e.target.value
										)
									}
									error={!!errors.paymentId}
									helperText={errors.paymentId}
									required
									InputProps={{
										endAdornment: (
											<IconButton
												size='small'
												onClick={() =>
													openInfoDialog('paymentId')
												}
											>
												<InfoIcon
													sx={{ color: '#03254C' }}
												/>
											</IconButton>
										),
									}}
								/>
							</Box>

							{/* Nostalgia Party */}
							<FormControlLabel
								control={
									<Checkbox
										checked={formData.joinNostalgia}
										onChange={(e) =>
											handleInputChange(
												'joinNostalgia',
												e.target.checked
											)
										}
										sx={{
											color: '#D0EFFF',
											'&.Mui-checked': {
												color: '#1167B1',
											},
										}}
									/>
								}
								label='Doresc să particip la Nostalgia Party'
								sx={{ color: '#D0EFFF' }}
							/>

							{formData.joinNostalgia && (
								<FormControl
									fullWidth
									error={!!errors.nostalgiaGame}
								>
									<InputLabel id='nostalgia-game-select-label'>
										Alege Jocul Nostalgia
									</InputLabel>
									<Select
										labelId='nostalgia-game-select-label'
										value={formData.nostalgiaGame}
										label='Alege Jocul Nostalgia'
										onChange={(e) =>
											handleInputChange(
												'nostalgiaGame',
												e.target.value
											)
										}
									>
										{nostalgiaGameOptions.map((game) => (
											<MenuItem
												key={game.value}
												value={game.value}
											>
												{game.label}
											</MenuItem>
										))}
									</Select>
									{errors.nostalgiaGame && (
										<Typography
											color='error'
											variant='caption'
										>
											{errors.nostalgiaGame}
										</Typography>
									)}
								</FormControl>
							)}

							{/* Terms and Submit */}
							<Stack
								sx={{
									fontSize: '15px',
									fontFamily: 'Poppins',
									fontWeight: 'bold',
									flexDirection: 'row',
									marginTop: 2,
									alignItems: 'center',
									width: 'fit-content',
									color:
										checkedConditionsErr &&
										!checkedConditions
											? 'red'
											: '#D0EFFF',
								}}
								onClick={() =>
									setCheckedConditions(!checkedConditions)
								}
							>
								<Checkbox
									checked={checkedConditions}
									sx={{ color: '#D0EFFF' }}
								/>
								<Box component='span'>
									Sunt de acord cu{' '}
									<Link
										href='/inscrieri_lan_party/gdpr'
										target='_blank'
										rel='noopener noreferrer'
										onClick={(e) => e.stopPropagation()}
										sx={{
											color: '#D0EFFF',
											textDecoration: 'underline',
											'&:hover': {
												color: 'white',
											},
										}}
									>
										termenii și condițiile
									</Link>
								</Box>
							</Stack>

							<Button
								type='submit'
								variant='contained'
								disabled={isSubmitting}
								sx={{
									background: '#1167B17A',
									borderRadius: '20px',
									fontSize: '20px',
									fontFamily: 'Poppins',
									fontWeight: 'bold',
									border: '2px solid #1167B1',
									marginTop: 2,
									'&:hover': {
										background: '#1167B1',
									},
								}}
							>
								{isSubmitting ? (
									<CircularProgress
										size={24}
										sx={{
											color: 'white',
										}}
									/>
								) : (
									'Trimite'
								)}
							</Button>
						</Stack>
					</form>

					{/* Dialogs */}
					<CustomDialog
						text={
							fieldInfoText[
								currentInfoField as keyof typeof fieldInfoText
							] || ''
						}
						isOpen={infoDialogOpen}
						setOpen={setInfoDialogOpen}
					/>

					<CustomDialog
						text='Cererea ta este in curs de procesare'
						isOpen={isSubmitting}
						setOpen={setIsSubmitting}
					></CustomDialog>

					<CustomDialog
						header='Regulament LAN Party'
						text={REGULATIONS}
						isOpen={eventInfoOpen}
						setOpen={setEventInfoOpen}
					/>
				</Box>
			</Box>

			{/* Feedback Snackbar */}
			<Snackbar
				open={feedbackOpen}
				autoHideDuration={6000}
				onClose={() => setFeedbackOpen(false)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Alert
					onClose={() => setFeedbackOpen(false)}
					severity={feedbackSeverity}
					sx={{ width: '100%' }}
				>
					{feedbackMessage}
				</Alert>
			</Snackbar>
		</ThemeProvider>
	)
}
