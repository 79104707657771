import type React from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { Upload, X } from 'lucide-react'

interface FileUploadProps {
	onFileSelect: (file: File) => void
	onFileRemove: () => void
	selectedFile?: File
	error?: string
}

export function FileUpload({
	onFileSelect,
	onFileRemove,
	selectedFile,
	error,
}: FileUploadProps) {
	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]
		if (file) {
			onFileSelect(file)
		}
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					border: '2px dashed',
					borderColor: error ? 'error.main' : '#D0EFFF',
					borderRadius: '10px',
					p: 1,
					textAlign: 'center',
					cursor: 'pointer',
					display: 'flex',
					mt: 1,
					height: '48px',
					maxWidth: '200px',
					'&:hover': {
						borderColor: 'white',
					},
				}}
				component='label'
			>
				<input
					type='file'
					accept='image/*'
					hidden
					onChange={handleFileSelect}
				/>
				{selectedFile ? (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
						<Typography sx={{ flex: 1, color: 'white' }}>
							{selectedFile.name.slice(0, 10)}
						</Typography>
						<IconButton
							onClick={(e) => {
								e.preventDefault()
								onFileRemove()
							}}
							size='small'
							sx={{ color: 'white' }}
						>
							<X />
						</IconButton>
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 1,
							width: '100%',
						}}
					>
						<Upload size={20} />
						<Typography sx={{ color: 'white', m: 0 }}>
							Încarcă legitimația
						</Typography>
					</Box>
				)}
			</Box>
			{error && (
				<Typography color='error' variant='caption' sx={{ mt: 1 }}>
					{error}
				</Typography>
			)}
		</Box>
	)
}
