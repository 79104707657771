import React, {forwardRef} from 'react';
import { Stack, Box, Typography } from '@mui/material';
import sponsorsImages from './../../../shared/sponsors/import';


type SponsorsImagesType = {
    [key: string]: string;
};

const typedSponsorsImages = sponsorsImages as SponsorsImagesType;

const Sponsors = forwardRef<HTMLDivElement>((props, ref) =>
{
    const images = Object.keys(typedSponsorsImages).map((key) => (
        <img
            src={typedSponsorsImages[key]}
            alt={key}
            key={key}
            style={{margin: '10px', width: '100px', height: 'auto'}}
        />
    ));

    return (
        <Stack ref={ref} alignContent="center" justifyContent="center" alignItems="center">
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "clamp(1.5rem, 30vw, 1.5rem)", md: "clamp(1.5rem, 30vw, 3rem)" },
                    alignSelf: { xs: "center", md: "center" },
                    textAlign: 'center',
                    paddingY: "10px",
                    paddingX: "3px"
                }}
            >
                Partenerii noștri
            </Typography>
            <Box sx={{overflow: 'hidden', whiteSpace: 'nowrap', width: '100%' }}>
                <Box className="animated-image" sx={{}}>
                    {images}
                    {images}
                    {images}
                    {images}
                    {images}
                    {images}
                    {images}
                    {images}
                </Box>
            </Box>
        </Stack>
    );
});

export default Sponsors;