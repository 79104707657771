import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import Home from '../../Pages/Home/Home'
import FAQ from '../../Pages/Questions'
import Inscrieri from '../../Pages/Inscrieri'
import Bal_Inscrieri from '../../Pages/Forms/Bal/Bal_Inscrieri'
import Bal_Inscrieri_Mentor from '../../Pages/Forms/Bal/Bal_Inscrieri_Mentor'
import Bal_Inscrieri_Prezentator from '../../Pages/Forms/Bal/Bal_Inscrieri_Prezentator'
import Inscrieri_LanParty from '../../Pages/Forms/LanPaty/Inscrieri_LanParty/'
import GDPRPage from '../../Pages/Forms/LanPaty/Inscrieri_LanParty/gdpr/page'
import Mentor_Inscrieri from '../../Pages/Forms/DevMe/Mentor_Inscrieri'
import ConfirmEmail from '../../Pages/ConfirmEmail'
import UnsubscribeEmail from '../../Pages/UnsubscribeEmail'

const routes: RouteObject[] = [
	{
		path: '/',
		element: <Home redirect='acasa' />,
	},
	{
		path: '/departamente',
		element: <Home redirect='departments' />,
	},
	{
		path: '/evenimente',
		element: <Home redirect='events' />,
	},
	{
		path: '/conducere',
		element: <Home redirect='leadership' />,
	},
	{
		path: '/sponsori',
		element: <Home redirect='sponsors' />,
	},
	{
		path: '/contact',
		element: <Home redirect='contact' />,
	},
	{
		path: '/faq',
		element: <FAQ />,
	},
	{
		path: '/inscrieri',
		element: <Inscrieri />,
	},
	{
		path: '/inscrieri_bal',
		element: <Bal_Inscrieri />,
	},
	{
		path: '/inscrieri_bal_mentor',
		element: <Bal_Inscrieri_Mentor />,
	},
	{
		path: '/inscrieri_bal_prezentator',
		element: <Bal_Inscrieri_Prezentator />,
	},
	{
		path: '/inscrieri_lan_party',
		element: <Inscrieri_LanParty />,
	},
	{
		path: '/inscrieri_lan_party/gdpr',
		element: <GDPRPage />,
	},
	{
		path: '/inscrieri_devme_mentori',
		element: <Mentor_Inscrieri/>
	},
	{
		path: '/confirm',
		element: <ConfirmEmail />,
	},
	{
		path: '/unsubscribe',
		element: <UnsubscribeEmail />
	}
]

export default routes
