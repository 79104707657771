import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useRef } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import TextField from "@mui/material/TextField";
import theme from "../../../shared/theme/newsletterTheme/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from 'react';
import axios from 'axios';

interface NewsletterModalProps {
    onClose?: () => void;
}
const NewsletterModal: React.FC<NewsletterModalProps> = ({ onClose }) => {

    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [email, setEmail] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        console.log(isEmailValid(event.target.value))
    };

    const handleSubscribeConfirm = async () => {
        try {
            const response = await axios.post('https://lsac-ucp.vercel.app/mail/api/subscribe', { email });
        } catch (ex) {
            console.log(ex);
        }
        setHasConfirmed(true);
    }

    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box onClick={onClose} width="100%" height="100%" sx={{ position: 'fixed', top: '0', left: '0', backgroundColor: 'rgba(0, 0, 0, 0.77)', zIndex: '5' }} />
            <Paper sx={{ position: 'fixed', width: { xs: '90%', md: 'auto' }, p: '1.5rem', m: '0', backgroundColor: 'white', left: '50%', gap: '20px', top: '50%', transform: 'translate(-50%, -50%)', zIndex: '10', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                <CloseIcon onClick={onClose} sx={{ position: 'relative', left: '50%', mb: '0px', cursor: 'pointer' }} />
                {!hasConfirmed ? (
                    <>
                        <Typography sx={{ color: 'black', fontWeight: '400', fontSize: '2rem', width: { xs: 'auto', md: 'max-content' } }}>Abonează-te la newsletter.</Typography>
                        <Typography sx={{ color: 'black', fontWeight: '300', fontSize: '1.2rem', width: { xs: 'auto', md: 'max-content' } }}>Pentru a fi la curent cu toate noutățile și evenimentele noastre.</Typography>
                        <Typography sx={{ color: 'black', fontWeight: '300', width: { xs: 'auto', md: 'max-content' } }}>Introdu adresa de email:</Typography>
                        <TextField type="email" sx={{ color: 'black' }} onChange={handleEmailChange} variant='outlined' />
                        <Button sx={{ fontSize: '1.4rem' }} disabled={!isEmailValid(email)} onClick={handleSubscribeConfirm}>Abonează-te!</Button>
                    </>) : (
                    <>
                        <Typography sx={{ color: 'black', fontWeight: '400', fontSize: '2rem', width: { xs: 'auto', md: 'max-content' } }}>Aproape te-ai abonat! 🤗</Typography>
                        <Typography sx={{ color: 'black', fontWeight: '300', fontSize: '1.2rem', width: { xs: 'auto', md: 'max-content' } }}>Verifică-ți adresa de email pentru a confirma abonarea!</Typography>
                    </>)}
            </Paper>
        </ThemeProvider>

    )
}

export default NewsletterModal