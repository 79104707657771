export const REGULATIONS = `Regulament LSAC LAN PARTY 2025

• Componența echipelor:
  - Echipele participante la competițiile League of Legends și Counter-Strike 2 trebuie să includă cel puțin trei studenți care studiază în municipiul Iași și doi elevi majori.

• Rezerve:
  - Fiecare echipă are posibilitatea de a înscrie o rezervă.
  - În cazul în care aceasta va fi utilizată, organizatorii trebuie informați înainte de începerea meciului.

• Canal de comunicare:
  - Toate echipele vor folosi serverul de Discord pus la dispoziție de organizatori pentru comunicarea internă.

• Conduită:
  - Este strict interzisă utilizarea unui limbaj injurios sau a oricăror comentarii ofensatoare la adresa altor echipe sau jucători.
  - Respectul reciproc este esențial pentru buna desfășurare a competiției.

• Supraveghere și fair-play:
  - Fiecare echipă va avea alocat un administrator care va oferi suport în cazul în care apar probleme tehnice sau organizatorice.
  - Administratorul își rezervă dreptul de a verifica orice situație suspectă (shady) care ar putea afecta integritatea competiției.

• Desfășurarea competiției FC 2025:
  - Meciurile din cadrul competiției FC 2025 se vor desfășura la sediul LSAC Iași (Cămin T19, Etaj 3).
  - Ne dorim să asigurăm o atmosferă plăcută, menținând un comportament civilizat și evitând orice deranj pentru ceilalți locatari ai căminului.

• Respectarea spațiului:
  - Spațiul oferit pentru desfășurarea competiției va fi predat în condiții optime de curățenie și ordine.
  - Participanții sunt obligați să-l mențină în același mod.

• Raportarea problemelor:
  - În cazul în care observați nereguli, vă rugăm să vă adresați administratorului desemnat.
  - Orice situație problematică va fi gestionată direct de către acesta.
  - Folosirea chat-ului în scopuri provocatoare sau deranjante este descurajată.

• Sancțiuni:
  - Nerespectarea oricărei reguli menționate mai sus poate duce la descalificarea echipei, fără posibilitatea returnării taxei de participare.

• Cost participare:
  - Taxa de participare este de 15 lei per participant (inclusiv căpitan și rezervă).
  - Plata se face prin Revolut la link-ul furnizat în formular.
  - Suma totală trebuie să reflecte numărul total de participanți înscriși.

Prin înscrierea în competiție, participanții acceptă în totalitate acest regulament.`

export const REVOLUT_PAYMENT_LINK = 'https://revolut.me/dumitrjz5z'

export const PARTICIPATION_FEE = 15 // lei per participant
