import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation } from 'react-router-dom'
import axios from 'axios';

const UnsubscribeEmail: React.FC = () => {
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const location = useLocation();

    const handleSubscriptionStatus = (status: boolean) => {
        setIsSubscribed(status);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        const confirmSubscription = async () => {
            try {
                const response = await axios.post('https://lsac-ucp.vercel.app/unsubscribe', { token });
                if (response.status === 200) {
                    setIsSubscribed(true);
                }
                else {
                    setIsSubscribed(false);
                }
                setIsLoading(false);
            } catch (ex) {
                console.log(ex);
            }
        }
        confirmSubscription();
    }, []);

    return (
<>
    {isLoading ? (
        <Typography>Loading...</Typography>
    ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
            {isSubscribed ? (
                <Typography sx={{ color: 'green', fontSize: '1.5rem', fontWeight: 'bold' }}>
                    You have been successfully unsubscribed to the newsletter!
                </Typography>
            ) : (
                <Typography sx={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold' }}>
                    There was an error unsubscribing to the newsletter. Please contact the administrator.
                </Typography>
            )}
        </Box>
    )}
</>
    );
}

export default UnsubscribeEmail;