import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	DialogActions,
	Button,
} from '@mui/material'
import type React from 'react'

const CustomDialog = ({
	header,
	text,
	isOpen,
	setOpen,
}: {
	header?: string
	text: string
	isOpen: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
	return (
		<>
			<Dialog open={isOpen} onClose={() => setOpen(false)}>
				<DialogTitle
					sx={{
						backgroundColor: '#03254C',
						color: 'white',
					}}
				>
					{header || 'Informatii'}
				</DialogTitle>
				<DialogContent
					sx={{
						paddingTop: 4,
						backgroundColor: 'primary.main',
						textWrap: 'wrap',
					}}
				>
					<Typography
						sx={{
							color: 'white',
							mt: 2,
						}}
					>
						{text}
					</Typography>
				</DialogContent>
				<DialogActions
					sx={{
						backgroundColor: 'primary.main',
					}}
				>
					{header && (
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							sx={{
								color: 'white',
								borderColor: 'white',
								backgroundColor: 'secondary.main',
								mx: 'auto',
							}}
						>
							Închide
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	)
}

export default CustomDialog
