import {
	Box,
	TextField,
	IconButton,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl,
	FormLabel,
	useMediaQuery,
	useTheme,
} from '@mui/material'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { FileUpload } from './FileUpload'
import type { TeamMember } from '../../types'
// import { useTheme } from '@emotion/react'
interface MemberFormProps {
	member: TeamMember
	index?: number
	onDelete?: () => void
	onChange: (updates: Partial<TeamMember>) => void
	errors: Record<string, string>
	isReserve?: boolean
}

export function MemberForm({
	member,
	index,
	onDelete,
	onChange,
	errors,
	isReserve = false,
}: MemberFormProps) {
	const prefix = isReserve ? 'reserve' : `teammates.${index}`

	const isXs = useMediaQuery('(max-width:700px)')
	return (
		<Box
			sx={{
				display: 'flex',
				gap: 2,
				width: '100%',
				justifyContent: 'space-between',
				flexDirection: { xs: 'column', sm: 'row' },
				flexWrap: 'wrap',
				background: 'rgba(255, 255, 255, 0.1)',
				p: 1,
				borderRadius: '12px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', sm: 'row' },
					gap: 2,
					width: {
						xs: '100%',
					},
				}}
			>
				<Box
					sx={{
						width: {
							sm: '50%',
						},
					}}
				>
					<TextField
						fullWidth
						label={
							isReserve
								? 'Nume Rezervă'
								: `Nume Coechipier #${index! + 1}`
						}
						value={member.name}
						onChange={(e) => onChange({ name: e.target.value })}
						error={!!errors[`${prefix}.name`]}
						helperText={errors[`${prefix}.name`]}
						required
					/>
				</Box>

				<Box
					sx={{
						width: {
							sm: '50%',
						},
					}}
				>
					<TextField
						fullWidth
						label={
							isReserve
								? 'ID Discord Rezervă'
								: `ID Discord Coechipier #${index! + 1}`
						}
						value={member.discordId}
						onChange={(e) =>
							onChange({ discordId: e.target.value })
						}
						error={!!errors[`${prefix}.discordId`]}
						helperText={errors[`${prefix}.discordId`]}
						required
						placeholder='username#0000'
					/>
				</Box>
				{onDelete && !isXs && (
					<Box
						onClick={onDelete}
						sx={{
							color: '#D0EFFF',
							width: '100px',
							mx: 'auto',
							display: 'flex',
							justifyContent: 'center',
							borderRadius: '10px',
							'&:hover': {
								color: 'white',
								backgroundColor: 'rgba(255, 0, 0, 0.1)',
								cursor: 'pointer',
							},
							py: 1,
						}}
					>
						<DeleteIcon
							sx={{
								height: '100%',
							}}
						/>
					</Box>
				)}
			</Box>

			<Box
				sx={{
					display: 'flex',
					gap: 2,
					alignItems: 'center',
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'center',
				}}
			>
				<FormControl
					component='fieldset'
					sx={{
						display: 'flex',
						flexDirection: 'row',
						width: {
							xs: '100%',
							md: 'auto',
						},
					}}
				>
					<FormLabel
						component='legend'
						sx={{
							color: 'white',
							mb: 1,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						Tip participant
					</FormLabel>
					<RadioGroup
						row
						value={member.type}
						onChange={(e) =>
							onChange({
								type: e.target.value as 'student' | 'pupil',
							})
						}
					>
						<FormControlLabel
							value='student'
							control={<Radio />}
							label='Student'
							sx={{ color: 'white', mr: 3 }}
						/>
						<FormControlLabel
							value='pupil'
							control={<Radio />}
							label='Elev'
							sx={{ color: 'white' }}
						/>
					</RadioGroup>
				</FormControl>

				<Box>
					<FileUpload
						onFileSelect={(file: File) =>
							onChange({ idCardFile: file })
						}
						onFileRemove={() => onChange({ idCardFile: undefined })}
						selectedFile={member.idCardFile}
						error={errors[`${prefix}.idCardFile`]}
					/>
				</Box>
			</Box>
			{isXs && onDelete && (
				<Box
					onClick={onDelete}
					sx={{
						color: '#D0EFFF',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						borderRadius: '10px',
						p: 1,
						'&:hover': {
							color: 'white',
							cursor: 'pointer',
							backgroundColor: 'rgba(255, 0, 0, 0.1)',
						},
						backgroundColor: 'rgba(255, 255, 255, 0.1)',
					}}
				>
					Sterge <DeleteIcon />
				</Box>
			)}
		</Box>
	)
}
