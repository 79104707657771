import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  FormGroup,
} from "@mui/material";
import theme from "./../../../../../shared/theme/theme";

const Form: React.FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedSpecializare, setSelectedSpecializare] = useState<string[]>([]);
  const [selectedLiber, setSelectedLiber] = useState<string[]>([]);
  const [selectedAn, setSelectedAn] = useState<string[]>([]);
  const [selectedZileInterviu, setSelectedZileInterviu] = useState<string[]>([]);
  const [selectedDepartamente, setSelectedDepartamente] = useState<string[]>([]);



  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedOptions.length < 1) {
      setSelectedOptions([...selectedOptions, value]);
    } else if (!event.target.checked) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleAnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked  && selectedAn.length < 1) {
      setSelectedAn([...selectedAn, value]);
    } else {
      setSelectedAn(selectedAn.filter((option) => option !== value));
    }
  };

  const handleSpecializareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedSpecializare.length < 1) {
      setSelectedSpecializare([...selectedSpecializare, value]);
    } else {
      setSelectedSpecializare(selectedSpecializare.filter((option) => option !== value));
    }
  }

  const handleLiberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked && selectedLiber.length < 1) {
      setSelectedLiber([...selectedLiber, value]);
    } else {
      setSelectedLiber(selectedLiber.filter((option) => option !== value));
    }
  }

  const handleZiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked  && selectedZileInterviu.length < 1) {
      setSelectedZileInterviu([...selectedZileInterviu, value]);
    } else {
      setSelectedZileInterviu(selectedZileInterviu.filter((option) => option !== value));
    }
  };

  const handleDepartamenteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (event.target.checked  && selectedDepartamente.length < 2) {
      setSelectedDepartamente([...selectedDepartamente, value]);
    } else {
      setSelectedDepartamente(selectedDepartamente.filter((option) => option !== value));
    }
  };

  const an = ["1", "2", "3", "4"];
  const specializare = ["CTI", "IS"];
  const zileInterviu = ["Sambata 22.03", "Duminica 23.03"];
  const liber = ["DA", "NU", "NU STIU"];
  const departamente =  ["IT", "HR", "PR", "FR",  "DESIGN"];

  return (
    <ThemeProvider theme={theme}>
      <Stack sx={{ gap: "8px", width: "100%", display: "flex", flexDirection: "column", height: "100%", }}>
        <TextField sx={{gap:"4px"}}required name="nume" label="1. Nume" fullWidth />
        <TextField required name="prenume" label="2. Prenume" fullWidth />
        <TextField required name="email" label="3. E-mail" fullWidth />
        <Stack gap={'4px'}>
          <Typography>
            4. An de studiu?
          </Typography>
          <FormGroup>
            {an.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedAn.includes(option)} onChange={handleAnChange} name="an" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <Stack gap={'4px'}>
          <Typography>
            5. Alegeti specializarea
          </Typography>
          <FormGroup>
            {specializare.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedSpecializare.includes(option)} onChange={handleSpecializareChange} name="specializare" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <Stack gap={'4px'}>
          <Typography>
            6. Alegeti ziua interviului?
          </Typography>
          <FormGroup>
            {zileInterviu.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedZileInterviu.includes(option)} onChange={handleZiChange} name="zileInterviu" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
        </Stack>
        <Stack gap={'4px'}>
          <Typography>
            7. Esti disponibil in periaoda 6-13 iulie?
          </Typography>
          <FormGroup>
            {liber.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedLiber.includes(option)} onChange={handleLiberChange} name="liber" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
          </Stack>
          <Stack gap={'4px'}>
          <Typography>
            8. In ce departament doresti sa lucrezi?
          </Typography>
          <FormGroup>
            {departamente.map((option) => (
              <FormControlLabel
                key={option}
                control={<Checkbox checked={selectedDepartamente.includes(option)} onChange={handleDepartamenteChange} name="departamente" value={option} />}
                label={option}
              />
            ))}
          </FormGroup>
          </Stack>
        </Stack>
    </ThemeProvider>
  );
};

export default Form;
