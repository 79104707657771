import React, { useState } from "react";
import { Grid2, Stack, Typography, Button } from "@mui/material";
import emailjs from "@emailjs/browser";
import Form from "./components/Form";
import Lsac from "./../../../../shared/images/3d_lsac.png";
import './../../../../App.css';

const Mentor_Inscrieri: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    const data = {
      type: 'participanti',
      nume: formData.get('nume')?.toString() || '',
      prenume: formData.get('prenume')?.toString() || '',
      email: formData.get('email')?.toString() || '',
      specializare: formData.get('specializare')?.toString() || '',
      camin: formData.get('camin')?.toString() || '',
      motivatie: formData.get('motivatie')?.toString() || '',
    };

    console.log("Date trimise:", data);

    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycby04q0owyo6W_Z-voWmkG-WZzA8x-n3FPuQq76jDdQr-bNR5sDK49vwcxHRBj_j4LuMIA/exec", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(data).toString(),
      });

      const result = await response.json();
      if (result.status === "success") {
        alert("Înregistrat cu succes!");
      } else {
        alert("A apărut o eroare!");
      }
    } catch (error) {
      console.error("Eroare la trimiterea datelor:", error);
      alert("A apărut o eroare la trimiterea datelor!");
    }
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // sendEmail(event);
    handleSubmit(event);
  };

  return (
    <Grid2
      container
      sx={{
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 0",
        overflowX: "hidden",
        backgroundSize: "cover",
      }}
    >
      <Grid2
        size={{ xs: 11, sm: 10, md: 7 }}
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          ">*:first-child": {
            gap: "16px",
            width: '100%',
            height: '100%',
            display: "flex",
            flexDirection: "column",
            padding: "32px",
            background: 'linear-gradient(45deg, #03254CA6 0%, #03254C 100%) ',
            backdropFilter: 'blur(4px)',
            borderRadius: '20px',
            border: '2px solid #D0EFFF99',
            overflow: "auto",
            position: 'relative',
            "&::-webkit-scrollbar": {
              width: {
                xs: "0px",
                md: "26px",
              },
              borderRadius: "13px",
              backgroundClip: "padding-box",
              border: "10px solid transparent",
              height: "15%",
            },
            "&::-webkit-scrollbar-thumb": {
              width: {
                xs: "0px",
                md: "26px",
              },
              borderRadius: "13px",
              backgroundClip: "padding-box",
              border: "10px solid transparent",
              boxShadow: "inset 0 0 0 10px",
              height: "15%",
            },
          },
        }}
      >
        <form
          method="POST"
          id="form"
          name="google-sheet"
          onSubmit={handleSubmit}
        >
          <Stack gap="8px">
            <Typography
              display="flex"
              variant="h4"
              fontWeight="800"
              color="#F3F3F3"
              flexDirection="row"
            >
              Înscrieri Mentori DevMe
            </Typography>
            <Form />
          </Stack>
          <Button type="submit" onClick={(e) => { setIsSubmitted(true)}} variant="contained" color="primary" 
            sx={{
              background: '#1167B17A',
              borderRadius: '20px',
              fontSize: '20px',
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              border: '2px solid #1167B1'
            }}
          >
            Trimite
          </Button>
        </form>
        <Stack
          component={"img"}
          src={Lsac}
          sx={{
            width: "auto",
            height: "100%",
            transform: 'rotate(-10deg) translate(-40px, 60px)',
            position: 'absolute',
            bottom: '0',
            left: '0',
            zIndex: '-1'
          }}
        />
      </Grid2>
    </Grid2>
  );
};

export default Mentor_Inscrieri;
