import React, { forwardRef } from 'react'
import { Stack, Grid2, Typography } from '@mui/material'

const Footer: React.FC = () => (
    <Grid2
    container
    sx={{
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "32px",
      marginBottom: '32px'
    }}
  >
    <Grid2
      size={{ xs: 12, md: 10 }}
      sx={{
        backgroundColor: "rgb(17, 103, 177, 0.4)",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: "40px",
        flexDirection: "column",
        gap: "24px",
        padding: "16px",
        overflow: "hidden",
        position: 'relative',
        alignItems: 'center',
      }}
    >
        <Typography>
        Copy © 2025 All rights reserved - LSAC Iași
        </Typography>
    </Grid2>
  </Grid2>
);

export default Footer